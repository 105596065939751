import logo from './logo.svg';
import './App.css';
import Topbar from './components/topbar';
import Navbar from './components/navbar';
import Hero from './components/hero';
import Featured_service from './components/featured_service';
import About from './components/about';
import Skills from './components/skills';
import Clients from './components/clients';
import Counts from './components/counts';
import Team from './components/team';
import Footer from './components/footer';
import Contact from './components/contact';
function App() {
  return (
    <div className="App">
      <Topbar/>
      <Navbar />
      <Hero />
      <Counts/>    
      <About/>  
      {/* <Clients/>      */}
      {/* <Skills/>           */}
      <Featured_service/>
      <Team/>
      <Contact/> 
      <Footer/>
    
    </div>
  );
}

export default App;
