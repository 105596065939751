import React from "react";
import "../App.css";
function Team() {

  return (
    <main id="main">

      <section id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>Team</h2>
            <h3>Our <span>Leadership</span> </h3>
          </div>

          

          <div class="row">
            <div class="col-xl-1 col-lg-4 col-md-4" data-wow-delay="0.1s">  </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
              <p class="fst-italic">
              <strong>Dr.Neeraj Gujar</strong> write up about 16 year experience in management, healthcare domain<br/><br/>

              <strong>Dr.Neeraj Gujar</strong> is an experienced healthcare management professional with 16 years of
                 experience in the industry. Over the years, he has gained a vast knowledge of healthcare management, 
                 including strategy development, business development, operations management, and team leadership.<br/>

                 <strong>Dr.Neeraj Gujar</strong> is a strong advocate for the use of technology in healthcare management. 
                He has been involved in the implementation of several healthcare technology solutions, 
                including electronic health records (EHRs), telemedicine, and healthcare analytics.<br/>

                Throughout his career,  <strong>Dr.Neeraj Gujar</strong>  has demonstrated a strong commitment to 
                healthcare excellence and patient-centered care. He is a strong leader who inspires 
                his teams to deliver high-quality healthcare services and achieve exceptional results.<br/>
                In conclusion, <strong>Dr.Neeraj Gujar's</strong> 16 years of experience in it and healthcare management
                 have equipped him with a wealth of knowledge and expertise in the field. His leadership,
                  strategic thinking, and commitment to healthcare excellence make him a valuable asset to
                   any healthcare organization.<br/>

              </p>
            </div>
            <br/>
            <br/>
            <br/>
            <div class="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.1s">
              <div class="member" data-aos="zoom-in" data-aos-delay="200">
          
                <img src="assets/img/team/neeraj_gujar.jpg" class="img-fluid" alt="" />
                <div class="member-info">
                  <div class="member-info-content">
                    <h4>Dr.Neeraj Gujjar</h4>
                    <span>Chief Executive Officer</span>
                  </div>
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
<br/><br/>
          <div class="row">
            <div class="col-xl-1 col-lg-4 col-md-4" data-wow-delay="0.1s">
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4" data-wow-delay="0.1s">
              <div class="member" data-aos="zoom-in" data-aos-delay="200">
                <img src="assets/img/team/sampurnanad.jpg" class="img-fluid" alt="" />
                <div class="member-info">
                  <div class="member-info-content">
                    <h4>Dr.Sampurnanad</h4>
                    <span>Product Manager</span>
                  </div>
                  <div class="social">
                    <a href=""><i class="bi bi-twitter"></i></a>
                    <a href=""><i class="bi bi-facebook"></i></a>
                    <a href=""><i class="bi bi-instagram"></i></a>
                    <a href=""><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
              <p class="fst-italic">
                "Meet <strong>Dr.Sampurnanand Singh</strong> , the driving force behind Neeyum Solutions LLP. With 15 years of experience in various
                industries and fields, <strong>Dr.Sampurnanand Singh</strong> brings a wealth of knowledge and expertise to our team.
                He has a proven track record of success and achievement, and his leadership and strategic thinking
                have been instrumental in the growth and success of our company. Under his direction,
                Neeyum Solutions LLP has achieved many milestones and established itself as a leader in the industry.
                We are thrilled to have <strong>Dr.Sampurnanand Singh</strong> on board and look forward to his continued contributions in the future."
              </p>
            </div>
          </div>

        </div>
      </section>
    </main>
  );
}

export default Team;
