import React from "react";
import "../App.css";
function Counts() {

    return (
        <main id="main">
            <section id="counts" class="counts">
                <div class="container" data-aos="fade-up">

                    <div class="row">
                  
                        <div class="col-lg-4 col-md-6">
                            <div class="count-box">
                                <i class="bi bi-emoji-smile"></i>
                                <span data-purecounter-start="0"
                                    data-purecounter-end="232"
                                    data-purecounter-duration="1"
                                    class="purecounter">
                              5
                                </span>

                                <p>Happy Clients</p>
                            </div>
                        </div>
{/* 
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div class="count-box">
                                <i class="bi bi-journal-richtext"></i>
                                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter">521</span>
                                <p>Projects</p>
                            </div>
                        </div> */}

                        <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                            <div class="count-box">
                                <i class="bi bi-headset"></i>
                                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter">500</span>
                                <p>Hours Of Support</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                            <div class="count-box">
                                <i class="bi bi-people"></i>

                                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter">7</span>
                                <p>Hard Workers</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </main>
    );
}

export default Counts;