import React from "react";
import "../App.css";

function Featured_service() {
    return (
        
  <main id="main">
        <section id="featured-services" class="featured-services">
        <div class="container" data-aos="fade-up">
        <div class="section-title">
            <h2>Services</h2>
            <h3>Check our <span>Services</span></h3>
            <p>Improve productivity, quality, and customer experience.</p>        
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bx bxl-dribbble"></i></div>
                <h4 class="title"><a href="">MOBILE APP DEVELOPMENT</a></h4>
                <p class="description">Harness The Power Of Mobile Apps</p>
              </div>
            </div>
  
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class="bx bx-file"></i></div>
                <h4 class="title"><a href="">WEB APP DEVELOPMENT</a></h4>
                <p class="description"> Dedicated To Afford Reliable Web Solutions</p>
              </div>
            </div>
  
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon"><i class="bx bx-tachometer"></i></div>
                <h4 class="title"><a href="">WEB DEVELOPMENT</a></h4>
                <p class="description">Dedicated To Afford Reliable Web Solutions</p>
              </div>
            </div>
  
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-world"></i></div>
                <h4 class="title"><a href="">Other service</a></h4>
                <p class="description">Dedicated To Afford Reliable Web Solutions</p>
              </div>
            </div>
  
          </div>
  
        </div>
      </section>
      </main>
    );
}

export default Featured_service;