import React from "react";
import "../App.css";

function About() {
  return (

    <main id="main">
      <section id="about" class="about section-bg">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>About</h2>
            <h3>Find Out More <span>About Us</span></h3>
            <p></p>
          </div>

          <div class="row">
            <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <img src="assets/img/about.jpg" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
              <h4 class="fst-italic">
                You focus on core business while we take care of your digital transformation.
              </h4>

              <ul>
                <li>
                  {/* <i class="bx bx-images"></i> */}
                  {/* <h5>Our Key Feature </h5> */}
                  <div>
                    <br />
                    <p><strong>Neeyum Solutions LLP </strong>is a software development company that has created an innovative
                      ERP (Enterprise Resource Planning) product designed specifically for the healthcare industry.
                      The company's flagship product is a comprehensive diagnostic solution that integrates pathology and laboratory
                      functions into a single system,providing a streamlined workflow for medical professionals</p>
                    <br />
                    <p> One of the key features of the <strong>Neeyum  ERP</strong> product is its automation of
                      the workflow in blood collection. This process has traditionally been a manual
                      and time-consuming task, which can be prone to errors. However, with the <strong>Neeyum ERP</strong>  solution,
                      the entire process can be automated, reducing the risk of errors and increasing the speed of blood
                      collection.
                    </p>
                    <br />
                    <p> The ERP solution also includes a range of other features that are designed
                      to streamline pathology and laboratory operations, including a comprehensive
                      diagnostic module that covers all aspects of laboratory testing, from sample
                      collection to result reporting. This allows healthcare providers to manage all
                      aspects of the diagnostic process from a single interface, improving efficiency and accuracy.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <br />
          <div class="row">

            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">

              <ul>
                <li>
                  {/* <i class="bx bx-images"></i> */}
                  {/* <h5>Our Key Feature </h5> */}
                  <div>
                    <br />
                    <p> In addition, the <strong>Neeyum  ERP</strong>  solution provides a range of other functions
                      that are essential for managing healthcare operations, such as inventory management,
                      patient management, and billing and financial management. The solution is designed to
                      be flexible and customizable, allowing healthcare providers to tailor the system to their specific needs.
                    </p>

                    <br />
                    <p>
                      Overall, the<strong>Neeyum Solutions LLP ERP</strong>   product represents a significant advancement in
                      healthcare technology, providing a holistic diagnostic solution that integrates pathology
                      and laboratory functions into a single system. 
                      </p>
                      <br/>
                      <p>The automation of the workflow in blood collection
                      and the range of other features included in the ERP solution make it an essential tool for healthcare
                      providers looking to streamline their operations and improve patient care.
                    </p>


                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <img src="assets/img/about-2.jpg" class="img-fluid" alt="" />
            </div>
          </div>



        </div>
      </section>
    </main>
  );
}

export default About;