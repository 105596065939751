import React from "react";
import "../App.css";


function Navbar() {
  const [modalShow, setModalShow] = React.useState(false);
  return (<div>
    <header id="header" class="d-flex align-items-center  fixed-top">
      <div class="container d-flex align-items-center justify-content-between">
        <h1 class="logo"><a href="index.html"> <img src="./assets/img/logo.png" alt="" />&nbsp;NEEYUM</a></h1>
        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
            <li><a class="nav-link scrollto" href="#about">About</a></li>
            <li><a class="nav-link scrollto" href="#featured-services">Services</a></li>
            <li><a class="nav-link scrollto" href="#team">Team</a></li>
            <li class="dropdown"><a href="#"><span>Technology</span> <i class="bi bi-chevron-down"></i></a>
              <ul>

                <li>
                  <a href="#">
                    <h6>React JS</h6>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h6>Laravel</h6>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h6>Angular</h6>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h6> Android</h6>
                  </a>
                </li>


                <li>
                  <a href="#">
                    <h6> Flutter</h6>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h6> Bootstrap</h6>

                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown"><a href="#"><span>Industries</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>HEALTHCARE
                      <h6 style={{ fontSize: 10 }}>  Bestowing The Power Of Digital Health</h6> </h5>
                  </a>
                </li>

                {/* <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>REAL ESTATE
                      <h6 style={{ fontSize: 10 }}>Tech-Driven Real Estate Software Solutions</h6> </h5>
                  </a>
                </li> */}

                <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>E-COMMERCE
                      <h6 style={{ fontSize: 10 }}>Building Feature-Rich Ecommerce Solutions</h6></h5>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>FINANCIAL SOFTWARE
                      <h6 style={{ fontSize: 10 }}>Eliminating Business Risks On Finances</h6></h5>
                  </a>
                </li>


                {/* <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>MANUFACTURING
                      <h6 style={{ fontSize: 10 }}>Digitizing Manufacturing Industry With Custom Solutions</h6></h5>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>SUPPLYCHAIN
                      <h6 style={{ fontSize: 10 }}>Ensuring SCM Solution Via Fruitful Strategies</h6></h5>
                  </a>
                </li> */}

                <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}>EDUCATION
                      <h6 style={{ fontSize: 10 }}>Gain The Aspects Of Digitized Education Systems</h6></h5>
                  </a>
                </li>

                {/* <li>
                  <a href="#">
                    <h5 style={{ fontSize: 12 }}> AUTOMOTIVE
                      <h6 style={{ fontSize: 10 }}>Feel The New Digital Experience In Automotive</h6></h5>
                  </a>
                </li> */}

              </ul>
            </li>
            <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>

      </div>
    </header>
  </div>
  );
}


export default Navbar;