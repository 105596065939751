import React from "react";
import "../App.css";

function Hero() {
    return (
        <section id="hero" class="d-flex align-items-center">
            <div class="container" data-aos="zoom-out" data-aos-delay="100">
                <h1>Welcome to <span>Neeyum</span></h1>
                <h2>We help you discover new ways to deliver your services

</h2>
                <div class="d-flex">
                    <a href="#about" class="btn-get-started scrollto">Get Started</a>
                    <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox btn-watch-video"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>
                </div>
            </div>
        </section>
    );
}

export default Hero;
